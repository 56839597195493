import * as React from 'react';
import { RouteComponentProps, useParams } from 'react-router';

import { Container } from '../layout/Container';
import { Heading } from '../ui/text/Heading';
import { Input } from '../ui/form/Input';
import { Paragraph } from '../ui/text/Paragraph';
import { Section } from '../layout/Section';
import { BackgroundLoad } from '../layout/BackgroundLoad';
import { GlobalSearch } from '../ui/search/cluster/Index';
import { useFadeTransition } from '../../utils/hooks/useFadeTransition';
import { Seo } from '../../utils/seo/Index';

const BrokerOfficeSearch: React.FC<RouteComponentProps<any>> = ({
  location,
  history
}) => {
  const { fadeIn } = useFadeTransition({
    immidiate: true
  });

  React.useEffect(() => {
    if (
      !location.search.match(/q=/) &&
      location.search !== '?alle' &&
      location.search !==
        '?nybygg-salg' /* Denne ønskes endret til ?nybygg-salg, og må endres i grafen, da den henter ut meglere basert på "nybygg" */
    ) {
      history.push({
        pathname: '/kontorer',
        search: '?alle'
      });
    }
  }, []);

  return (
    <>
      <Seo
        title="Finn en megler eller kontor - PrivatMegleren"
        description={
          location.search === '?alle'
            ? 'PrivatMegleren sine eiendomsmeglere hjelper deg med ditt boligsalg'
            : location.search === '?nybygg-salg'
            ? 'PrivatMegleren sine eiendomsmeglere skreddersyr ditt boligsalg eller kjøp tilpasset dine behov'
            : null
        }
        image="https://cdn.reeltime.no/pm_assets/img/bakgrunner/pmfront.jpg"
        imageTitle={
          location.search === '?alle'
            ? '/salg-av-bolig-verdivurdering'
            : location.search === '?nybygg-salg'
            ? '/selge-nybygg-kontakt-PrivatNegleren'
            : null
        }
        url={location.pathname}
      />

      <Container style={{ paddingTop: '100px', minHeight: '1000px' }}>
        <Section style={fadeIn}>
          <Heading tag="h1" center={true}>
            Finn eiendomsmegler eller kontor
          </Heading>
          <GlobalSearch type="broker_office" />
        </Section>
      </Container>
    </>
  );
};

export default BrokerOfficeSearch;
